import React from 'react';
import { exibirDadosGraficoProdutosVendidos, exibirGraficoVendasQuantidadeVendas, exibirGraficoVendasValorImplantacao, exibirGraficoVendasValorMensalidade, prepararDadosGraficoProdutosVendidos } from '../utils/ChartUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpWideShort, faBox, faFileInvoiceDollar, faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons';


const ModalGraficoVendas = ({ clientesFiltradoVendas }) => {
  return (
    <div className="modal fade" tabIndex="-1" id="modalGraficoVendas">
      <div className="modal-dialog modal-fullscreen p-lg-3">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Detalhes de venda</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 col-lg-6 mb-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className='text-muted mb-4'><FontAwesomeIcon icon={faArrowUpWideShort} /> Vendas por quantidade</h5>
                    {exibirGraficoVendasQuantidadeVendas(clientesFiltradoVendas)}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className='text-muted mb-4'><FontAwesomeIcon icon={faMoneyBillTrendUp} /> Vendas por implantação</h5>
                    {exibirGraficoVendasValorImplantacao(clientesFiltradoVendas)}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className='text-muted mb-4'><FontAwesomeIcon icon={faFileInvoiceDollar} /> Vendas por mensalidade</h5>
                    {exibirGraficoVendasValorMensalidade(clientesFiltradoVendas)}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className='text-muted mb-4'><FontAwesomeIcon icon={faBox} /> Vendas por produto</h5>
                    {exibirDadosGraficoProdutosVendidos(clientesFiltradoVendas)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalGraficoVendas