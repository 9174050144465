import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faLock, faHotel, faLocationDot, faAddressBook, faBoxOpen, faTicket, faDoorClosed, faUser } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

const ModalDetalheCliente = ({ showModal, handleCloseModal, clienteSelecionado }) => {
    const handleBloquarFranquiado = async () => {

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(clienteSelecionado),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostBloquearFranquiado', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostBloquearFranquiado', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        handleCloseModal();

        window.location.reload();
    };

    const handleLiberarFranquiado = async () => {

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(clienteSelecionado),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostDesbloquearFranquiado', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostDesbloquearFranquiado', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao enviar o formulário ');
        }

        handleCloseModal();

        window.location.reload();
    };

    return (
        <div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-white bg-dark">
                                <h5 className="modal-title"><FontAwesomeIcon icon={faUser} /> Cliente</h5>
                                <button type="button" className="btn-close btn-close-white" onClick={handleCloseModal}>
                                    {/*<span aria-hidden="true">&times;</span>*/}
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faHotel} /> Dados Empresa
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="inputRazao" className="form-label">Razão Social</label>
                                                        <input type="text" className="form-control " disabled id="inputRazao" defaultValue={clienteSelecionado?.Nome?.trim()} />
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="inputFantasia" className="form-label">Nome Fantasia</label>
                                                        <input type="text" className="form-control " disabled id="inputFantasia" defaultValue={clienteSelecionado?.NomeCurto} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCnpj" className="form-label">Cnpj</label>
                                                        <input type="text" className="form-control " disabled id="inputCnpj" defaultValue={clienteSelecionado.Cnpj} />
                                                    </div>
                                                    <div className="col-6 col-sm-6">
                                                        <label htmlFor="inputIe" className="form-label">Ie</label>
                                                        <input type="text" className="form-control " disabled id="inputIe" defaultValue={clienteSelecionado.Ie} />
                                                    </div>
                                                    <div className="col-6 col-sm-6">
                                                        <label htmlFor="inputIm" className="form-label">Im</label>
                                                        <input type="text" className="form-control " disabled id="inputIm" defaultValue={clienteSelecionado.Im} />
                                                    </div>
                                                    <div className="col-6 col-sm-6">
                                                        <label htmlFor="inputCRT" className="form-label">CRT</label>
                                                        <input type="text" className="form-control " disabled id="inputCRT" defaultValue={clienteSelecionado?.CRT} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faLocationDot} /> Endereço
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputEndereco" className="form-label">Endereço</label>
                                                        <input type="text" className="form-control " disabled id="inputEndereco" defaultValue={clienteSelecionado?.Endereco?.trim()} />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="inputCidade" className="form-label">Cidade</label>
                                                        <input type="text" className="form-control " disabled id="inputCidade" defaultValue={clienteSelecionado?.Cidade?.trim()} />
                                                    </div>
                                                    {/*<div className="col-5 col-sm-3">*/}
                                                    {/*    <label htmlFor="inputCep" className="form-label">Cep</label>*/}
                                                    {/*    <input type="text" className="form-control " disabled id="inputCep" defaultValue={clienteSelecionado?.CdCEP?.trim()} />*/}
                                                    {/*</div>*/}
                                                    <div className="col-3 col-sm-2">
                                                        <label htmlFor="inputUf" className="form-label">Uf</label>
                                                        <input type="text" className="form-control" disabled id="inputUf" defaultValue={clienteSelecionado?.Uf?.trim()} />
                                                    </div>
                                                    <div className="col-5 col-sm-4">
                                                        <label htmlFor="inputBairro" className="form-label">Bairro</label>
                                                        <input type="text" className="form-control" disabled id="inputBairro" defaultValue={clienteSelecionado?.Bairro?.trim()} />
                                                    </div>
                                                    {/*<div className="col-5 col-sm-3">*/}
                                                    {/*    <label htmlFor="inputNumero" className="form-label">Nº</label>*/}
                                                    {/*    <input type="text" className="form-control" disabled id="inputNumero" defaultValue={clienteSelecionado?.NrLogradouro?.trim()} />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="col-12">*/}
                                                    {/*    <label htmlFor="inputComplemento" className="form-label">Complemento</label>*/}
                                                    {/*    <input type="text" className="form-control" disabled id="inputComplemento" defaultValue={clienteSelecionado?.DsComplemento?.trim()} />*/}
                                                    {/*</div>*/}
                                                    {/*<div className="col-12">*/}
                                                    {/*    <label htmlFor="inputParecer" className="form-label">Parecer</label>*/}
                                                    {/*    <input type="text" className="form-control" disabled id="inputParecer" defaultValue={clienteSelecionado?.DsParecer?.trim()} />*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow mb-3">
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faAddressBook} /> Contato
                                            </div>
                                            <div className="card-body" style={{ height: '170px', overflow: 'auto' }}>
                                                <div className="table-responsive rounded" >
                                                    <table className="table">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col">Tipo</th>
                                                                <th scope="col">Contato</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clienteSelecionado.Contatos.map((contato) => (
                                                                <tr key={(contato.IdPessoa ?? contato.DSContato) + contato.NmTipoContato}>
                                                                    <td className="text-truncate">{contato.NmTipoContato}</td>
                                                                    <td className="text-truncate">{contato.DSContato}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow rounded" >
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faBoxOpen} /> Caracteristicas
                                            </div>
                                            <div className="card-body" style={{ height: '170px', overflow: 'auto' }}>
                                                <div className="align-items-center">
                                                    {clienteSelecionado && clienteSelecionado.Caracteristicas.map((carac) => (
                                                        <img key={carac.IdCaracteristicaPessoa} width="60px" height="60px" className="img-fluid rounded p-1" alt={carac.NmCaracteristicaPessoa} title={carac.NmCaracteristicaPessoa} src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + carac.NmCaracteristicaPessoa} />

                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card shadow rounded" >
                                            <div className="card-header text-white bg-dark">
                                                <FontAwesomeIcon icon={faTicket} /> Boletos
                                            </div>
                                            <div className="card-body" style={{ height: '200px', overflow: 'auto' }}>
                                                <div className="table-responsive rounded" >
                                                    <table className="table">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col">Produto</th>
                                                                <th scope="col">Nº Titulo</th>
                                                                <th scope="col">Dt. Emissão</th>
                                                                <th scope="col">Dt. Vencimento</th>
                                                                <th scope="col">Valor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clienteSelecionado.aReceber.map((rec) => (
                                                                <tr key={rec.IdAReceber} className={`${(new Date(rec.DtVencimento) < new Date()) ? 'table-warning' : ''}`}>
                                                                    <td className="text-truncate ">
                                                                        <div className="align-items-center">
                                                                            {
                                                                                (clienteSelecionado.Caracteristicas.find(c => { return c.idProduto == rec.idProduto })?.NmCaracteristicaPessoa != null)
                                                                                    ? <img width="40px" height="40px" className="img-fluid rounded p-1" src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + clienteSelecionado.Caracteristicas.find(c => { return c.idProduto == rec.idProduto })?.NmCaracteristicaPessoa} />
                                                                                    : <></>
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                    <td className="text-truncate">{rec.NrTitulo}</td>
                                                                    <td className="text-truncate">{format(Date.parse(rec.DtEmissao), 'dd/MM/yyyy')}</td>
                                                                    <td className="text-truncate">{format(Date.parse(rec.DtVencimento), 'dd/MM/yyyy')}</td>
                                                                    <td className={`text-truncate`}>{rec.VlTitulo}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {(clienteSelecionado.UsuarioFranquia != null)
                                    ? (!clienteSelecionado.Bloqueado)
                                        ? <button type="button" className="btn btn-danger" onClick={handleBloquarFranquiado}>
                                            <FontAwesomeIcon icon={faLock} /> Bloquear
                                        </button>
                                        : <button type="button" className="btn btn-success" onClick={handleLiberarFranquiado}>
                                            <FontAwesomeIcon icon={faLockOpen} /> Liberar
                                        </button>
                                    : ''
                                }
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    <FontAwesomeIcon icon={faDoorClosed} /> Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalDetalheCliente;