import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faList, faUser, faUsers, faCircleUser, faScrewdriverWrench, faRectangleXmark, faBox, faPlusCircle, faChartSimple, faFileInvoice, faImage } from '@fortawesome/free-solid-svg-icons';
import logo from '../images/rcabranca.png';

const Navbar = ({ user }) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <img src={logo} alt="Logo" href="/" className="navbar-brand" style={{ maxWidth: '100px', marginRight: '10px' }} />
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/"><FontAwesomeIcon icon={faList} /> Clientes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/novo"><FontAwesomeIcon icon={faUserPlus} /> Lançar Cliente</a>
                        </li>

                        {/* {user?.Perfil == 4 &&
                            (
                                <li><a className="dropdown-item" href="/autorizacao"><FontAwesomeIcon icon={faUsers} /> Autorizar Venda</a></li>
                            )} */}

                        {
                            (user?.Perfil == 1) ?
                                <>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faScrewdriverWrench} /> Menu Administrativo
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><a className="dropdown-item" href="/autorizacao"><FontAwesomeIcon icon={faUsers} /> Autorizar Venda</a></li>
                                            <li><a className="dropdown-item" href="/franquiados"><FontAwesomeIcon icon={faCircleUser} /> Franqueados</a></li>
                                            <li><a className="dropdown-item" href="/produtos"><FontAwesomeIcon icon={faBox} /> Produtos</a></li>
                                            <li><a className="dropdown-item" href="/questionario"><FontAwesomeIcon icon={faPlusCircle} /> Cadastro de Questionário</a></li>
                                            <li><a className="dropdown-item" href="/WhiteLabel"><FontAwesomeIcon icon={faImage} /> White Label</a></li>
                                        </ul>
                                    </li>
                                </>
                                :
                                ""
                        }
                        {


                            (user?.Perfil == 4) ?
                                <>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faScrewdriverWrench} /> Menu
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><a className="dropdown-item" href="/autorizacao"><FontAwesomeIcon icon={faFileInvoice} /> Visualizar Vendas</a></li>
                                            {/* <li><a className="dropdown-item" href="/franquiados"><FontAwesomeIcon icon={faCircleUser} /> Franqueados</a></li> */}
                                            {/* <li><a className="dropdown-item" href="/produtos"><FontAwesomeIcon icon={faBox} /> Produtos</a></li> */}
                                            {/* <li><a className="dropdown-item" href="/questionario"><FontAwesomeIcon icon={faPlusCircle} /> Cadastro de Questionário</a></li> */}
                                        </ul>
                                    </li>
                                </>
                                :
                                ""
                        }
                    </ul>
                    <div className="d-flex">
                        <p className="text-capitalize text-white m-0">
                            <FontAwesomeIcon icon={faUser} />
                            {user.NomeUsuario?.toUpperCase()}
                            <a type="button" className="text-white m-2" href="/logout">
                                <FontAwesomeIcon icon={faRectangleXmark} />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
