import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const WhiteLabel = ({ initialData, onSubmit, onBack }) => {
    const defaultData = {
        idTemasFranquia: "",
        IdCaracteristicaPessoa: "",
        images: [
            { name: "LogoLogin1", base64: null },
            { name: "LogoLogin2", base64: null },
            { name: "LogoPrincipal", base64: null },
            { name: "LogoPrincipalCinza", base64: null },
            { name: "LogoMenu", base64: null },
            { name: "LogoRelatorio", base64: null }
        ],
        primaryColor: '#ffffff',
        secondaryColor: '#000000',
        contato: {
            nomeFantasia: "",
            nomeEmpresarial: "",
            nomeContato: "",
            cnpj: "",
            logradouro: "",
            numero: "",
            cep: "",
            municipio: "",
            uf: "",
            email: "",
            telefone: "",
            site: "",
            idCSRT: "",
            hashCSRT: ""
        }
    };

    const [data, setData] = useState(defaultData);
    const [caracteristica, setCaracteristica] = useState([]);

    useEffect(() => {
        if (initialData) {
            setData(initialData);
        }
    }, [initialData]);

    useEffect(() => {
        fetchCaracteristicas()
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(name);
        setData({ ...data, [name]: value.toUpperCase() });
        // console.log(data);
    };

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImages = [...data.images];
                newImages[index].base64 = reader.result;
                setData({ ...data, images: newImages });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleColorChange = (e, colorType) => {
        setData({ ...data, [colorType]: e.target.value });
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, contato: { ...data.contato, [name]: value } });
    };

    const handleSubmit = () => {
        onSubmit(data);
    };

    const fetchCaracteristicas = async () => {
        try {
            //const response = await fetch('http://localhost:63299/api/GetCaracteristica');
            const response = await fetch('https://api.erp.rcasistemas.com.br/api/GetCaracteristica');
            if (!response.ok) {
                throw new Error('Erro ao carregar Caracteristica');
            }
            const data = await response.json();

            //console.log(data);
            setCaracteristica(data);
        } catch (error) {
            console.error('Erro ao carregar Caracteristica:', error);
        } finally {

        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">{initialData ? "Editar WhiteLabel" : "Criar WhiteLabel"}</h2>

            <div className="mb-4">
                <div className="col-12 col-sm-6">
                    <label htmlFor="selectCarac" className="form-label">Características</label>
                    <select className="form-select form-select-sm" id="selectCarac" aria-label=".form-select-sm caract" name="IdCaracteristicaPessoa" onChange={handleChange} value={data.IdCaracteristicaPessoa || ''}>
                        <option>Selecione</option>
                        {
                            caracteristica.map((carac) => (
                                <option key={carac.IdCaracteristicaPessoa} value={carac.IdCaracteristicaPessoa}>
                                    {carac.NmCaracteristicaPessoa}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="mb-4">
                <h2>Informações de Contato</h2>
                <form>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Nome Fantasia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="nomeFantasia"
                                    value={data.contato.nomeFantasia}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Nome Empresarial</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="nomeEmpresarial"
                                    value={data.contato.nomeEmpresarial}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Nome Contato</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="nomeContato"
                                    value={data.contato.nomeContato}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>CNPJ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="cnpj"
                                    value={data.contato.cnpj}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Logradouro</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="logradouro"
                                    value={data.contato.logradouro}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Número</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="numero"
                                    value={data.contato.numero}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>CEP</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="cep"
                                    value={data.contato.cep}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Município</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="municipio"
                                    value={data.contato.municipio}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>UF</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="uf"
                                    value={data.contato.uf}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    value={data.contato.email}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Telefone</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    name="telefone"
                                    value={data.contato.telefone}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Site</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="site"
                                    value={data.contato.site}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Id CSRT</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="idCSRT"
                                    value={data.contato.idCSRT}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="form-group">
                                <label>Hash CSRT</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="hashCSRT"
                                    value={data.contato.hashCSRT}
                                    onChange={handleContactChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="row mb-4">
                <div className="col-md-6 mb-4">
                    <h2>Selecione uma Cor Primária</h2>
                    <input
                        type="color"
                        value={data.primaryColor}
                        onChange={(e) => handleColorChange(e, 'primaryColor')}
                        className="form-control form-control-color"
                    />
                    <div className="mt-2">
                        <span>Cor Primária selecionada: {data.primaryColor}</span>
                        <div
                            style={{
                                width: '100px',
                                height: '100px',
                                backgroundColor: data.primaryColor,
                                marginTop: '10px'
                            }}
                        ></div>
                    </div>
                </div>

                <div className="col-md-6 mb-4">
                    <h2>Selecione uma Cor Secundária</h2>
                    <input
                        type="color"
                        value={data.secondaryColor}
                        onChange={(e) => handleColorChange(e, 'secondaryColor')}
                        className="form-control form-control-color"
                    />
                    <div className="mt-2">
                        <span>Cor Secundária selecionada: {data.secondaryColor}</span>
                        <div
                            style={{
                                width: '100px',
                                height: '100px',
                                backgroundColor: data.secondaryColor,
                                marginTop: '10px'
                            }}
                        ></div>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <h2>Informações da Logo</h2>
                {data.images.map((image, index) => (
                    <div key={index} className="col-md-4 mb-4">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title">{image.name}</h5>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e, index)}
                                    className="form-control mb-2"
                                />
                                {image.base64 && (
                                    <img
                                        src={image.base64}
                                        alt={image.name}
                                        className="card-img-top mb-2"
                                        style={{ objectFit: 'scale-down', height: '150px', width: '100%' }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mb-4">
                <button
                    onClick={handleSubmit}
                    className="btn btn-primary"
                >
                    {initialData ? "Salvar Alterações" : "Criar WhiteLabel"}
                </button>
                <button
                    onClick={onBack}
                    className="btn btn-secondary ml-3"
                >
                    Voltar
                </button>
            </div>
        </div>
    );
};

export default WhiteLabel;
