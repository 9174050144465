import React from 'react';

const QuestionarioImplatancao = () => {
  return (
    <div className="row">
      <div className="col-12 col-md-6 mx-auto">
        <h2>Questionário de Implantação</h2>
        <form>
          <div className="mb-3">
            <label htmlFor="pergunta" className="form-label">Pergunta</label>
            <input type="text" className="form-control" id="pergunta" placeholder="Digite a pergunta"/>
          </div>
          <div className="mb-3">
            <label htmlFor="tipoResposta" className="form-label">Tipo de Resposta</label>
            <select className="form-select" id="tipoResposta">
              <option value="">Selecione o tipo de resposta</option>
              <option value="texto">Texto (Resposta livre)</option>
              <option value="select">Seleção</option>
              <option value="checkbox">Checkbox</option>
              <option value="radio">Opção única (Radio)</option>
              {/* Adicione mais opções conforme necessário */}
            </select>
          </div>
          <button type="submit" className="btn btn-primary">Salvar</button>
        </form>
      </div>
    </div>
  );
};

export default QuestionarioImplatancao;
