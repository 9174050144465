import React from 'react';

const Logout = ({ onLogout }) => {
    React.useEffect(() => {
        onLogout();
        // Limpa as informações do usuário do localStorage ao fazer o logout
        localStorage.removeItem('user');
    }, [onLogout]);

    return (
        <div>
            <h2>Logout</h2>
            <p>Você saiu da sua conta.</p>
        </div>
    );
};

export default Logout;
