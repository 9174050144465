import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import WhiteLabel from './WhiteLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlus, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const WhitelabelPage = ({ user }) => {
    const [mode, setMode] = useState('view'); // 'view', 'create', 'edit'
    const [currentData, setCurrentData] = useState(null);
    const [themes, setThemes] = useState([]);

    useEffect(() => {
        const fetchThemes = async () => {
            try {
                //const response = await fetch('http://localhost:63299/franquia/GetTema');
                const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetTema');
                if (response.ok) {
                    const data = await response.json();
                    // console.log(data);
                    setThemes(data);
                } else {
                    throw new Error('Erro ao buscar temas');
                }
            } catch (error) {
                console.error('Erro ao buscar temas:', error);
            }
        };

        fetchThemes();
    }, []);

    const handleCreate = () => {
        setCurrentData(null);
        setMode('create');
    };

    const handleEdit = (data) => {
        setCurrentData(data);
        setMode('edit');
    };

    const handleBack = () => {
        setMode('view');
    };

    const handleSubmit = async (data) => {
        // console.log('Submitted Data:', data);

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostTema', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostTema', requestOptions);

        if (response.ok) {
            setMode('view');
            //const updatedThemes = await fetch('http://localhost:63299/franquia/GetTema').then(res => res.json());
            const updatedThemes = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetTema').then(res => res.json());
            setThemes(updatedThemes);
        } else {
            console.log('Erro ao salvar o tema da franquia');
            throw new Error('Erro ao salvar o tema da franquia');
        }
    };

    return (
        <div className="container mt-4">
            {mode === 'view' && (
                <>
                    <h2 className="mb-4"><FontAwesomeIcon icon={faImage} /> White label</h2>
                    <button className="btn btn-dark mb-4" onClick={handleCreate}>
                        <FontAwesomeIcon icon={faPlus} /> Criar Novo Whitelabel
                    </button>
                    <div className="row">
                        {themes.map((theme, index) => (
                            <div key={index} className="col-md-4 mb-3">
                                <div className="card" style={{ backgroundColor: theme.secondaryColor, borderColor: theme.primaryColor }}>
                                    <div className="card-body">
                                        {theme.images[2].base64 && (
                                            <img
                                                src={theme.images[2].base64}
                                                alt={theme.images[2].name}
                                                className="card-img-top mb-2"
                                                style={{ objectFit: 'scale-down', height: '150px', width: '100%' }}
                                            />
                                        )}
                                        <h5 className="card-title text-truncate text-uppercase">{theme.contato.nomeFantasia}</h5>
                                        <p className="card-text">CNPJ: {theme.contato.cnpj}</p>
                                        <button
                                            className="btn btn-dark"
                                            onClick={() => handleEdit(theme)}
                                        >
                                            <FontAwesomeIcon icon={faPenToSquare} /> Editar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {(mode === 'create' || mode === 'edit') && (
                <WhiteLabel
                    initialData={currentData}
                    onSubmit={handleSubmit}
                    onBack={handleBack}
                />
            )}
        </div>
    );
};

export default WhitelabelPage;
