export const requestOptionsPOST = (data) => {
    var requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    return requestOptions;
}

export const requestOptionsPUT = (data) => {
    var requestOptions = {
        method: 'PUT',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data),
        redirect: 'follow'
    };

    return requestOptions;
}

export const requestOptionsGET = (params) => {
    var requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": 'application/json'
        }
    };

    return requestOptions;
}