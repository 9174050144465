import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesPacking, faBox, faPen } from '@fortawesome/free-solid-svg-icons';

const Produtos = ({ user }) => {
    const [produtos, setProdutos] = useState([]);
    const [produtosAutorizados, setProdutosAutorizados] = useState([]);

    const salvarProdutosAutorizados = async (produtomodal, principal) => {

        const data = new Date();

        var prodEnviar = {
            IdProduto: produtomodal.IdProduto,
            Nome: produtomodal.NmProduto,
            Principal: principal,
            DataAlteracao: data.toJSON()
        };

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(prodEnviar),
            redirect: 'follow'
        };

        //const response = await fetch('http://localhost:63299/franquia/PostProdutosAutorizados', requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/PostProdutosAutorizados', requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao salvar produto autorizado');
        }

        buscarProdutosAutorizados();

    };
    const removerProdutosAutorizados = async (idProdutoAutorizado) => {

        var requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            redirect: 'follow'
        };
        //const response = await fetch('http://localhost:63299/franquia/DeleteProdutosAutorizados?idProdutoAutorizado=' + idProdutoAutorizado, requestOptions);
        const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/DeleteProdutosAutorizados?idProdutoAutorizado=' + idProdutoAutorizado, requestOptions);

        if (!response.ok) {
            throw new Error('Erro ao salvar produto autorizado');
        }

        buscarProdutosAutorizados();

    };
    const buscarProdutosAutorizados = async () => {
        try {
            //const response = await fetch('http://localhost:63299//franquia/GetProdutosAutorizados');
            const response = await fetch('https://api.erp.rcasistemas.com.br/franquia/GetProdutosAutorizados');

            if (!response.ok) {
                throw new Error('Erro ao carregar produtos autorizados');
            }

            const prodAutRet = await response.json();
            setProdutosAutorizados(prodAutRet);
        } catch (e) {
            console.error(e);
        }
    };
    const BuscarPodutosERP = async () => {
        try {
            const response = await fetch('https://api.erp.rcasistemas.com.br/api/getProduto?nomeProduto=');
            if (!response.ok) {
                throw new Error('Erro ao carregar produtos');
            }
            const data = await response.json() || [];

            const ret = data?.filter((prod) => {
                return (prod.IdCaracteristicaPessoa != null);
            });


            setProdutos(ret);
        } catch (error) {
            console.error('Erro ao carregar Produtos:', error);
        } finally {

        }
    };

    useEffect(() => {
        buscarProdutosAutorizados();
        BuscarPodutosERP();
    }, [user]);

    return (
        <div className="row">
            <div className="col-12 col-md-5">
                <div className="card shadow-lg rounded" >
                    <div className="table-responsive rounded">
                        <div className="card-header text-white bg-dark">
                            <div className="d-flex justify-content-between">
                                <div className="card-title ">
                                    <FontAwesomeIcon icon={faBox} />&nbsp;&nbsp;Produtos
                                </div>
                            </div>
                        </div>
                        <div className="card-body custom-scrollbar">
                            <div className="row d-flex justify-content-between">
                                {produtos.map((produto, idx) => (
                                    <div key={produto.IdProduto} className={`card col-auto m-2 ${produtosAutorizados?.filter((prod) => prod.IdProduto == produto.IdProduto)?.length > 0 ? (!produtosAutorizados?.filter((prod) => prod.IdProduto == produto.IdProduto)[0]?.Principal ? 'bg-warning' : 'bg-primary') : ''}`} >
                                        <div className="card-body">
                                            <div className="align-items-center">
                                                <img width="60px" height="60px" className="img-fluid rounded p-1" src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + produto?.IdCaracteristicaPessoa} />
                                            </div>
                                        </div>
                                        <span id="btnGroupDrop1" data-bs-toggle="dropdown" aria-expanded="false" className={`position-absolute ${(produtosAutorizados?.filter((prod) => prod.IdProduto == produto.IdProduto).length <= 0) ? 'btn' : ' disabled'} top-100 start-50 translate-middle badge rounded-pill text-dark text-truncate ${produtosAutorizados?.filter((prod) => prod.IdProduto == produto.IdProduto)?.length > 0 ? (!produtosAutorizados?.filter((prod) => prod.IdProduto == produto.IdProduto)[0]?.Principal ? 'bg-warning' : 'bg-primary') : ''}`}>
                                            {(produtos?.filter((prod) => { return prod.IdProduto == produto.IdProduto })[0]?.NmProduto ?? '').split(0, 5)}
                                            <span className="visually-hidden">unread messages</span>
                                        </span>
                                        {
                                            (produtosAutorizados?.filter((prod) => prod.IdProduto == produto.IdProduto).length > 0)
                                                ? <>
                                                </>
                                                : <>
                                                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                                        <li><a className="dropdown-item" onClick={() => salvarProdutosAutorizados(produto, true)}><FontAwesomeIcon icon={faBox} />&nbsp;&nbsp;Principal</a></li>
                                                        <li><a className="dropdown-item" onClick={() => salvarProdutosAutorizados(produto, false)}><FontAwesomeIcon icon={faBoxesPacking} />&nbsp;&nbsp;Agregado</a></li>
                                                    </ul>
                                                </>
                                        }
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-7">
                <div className="accordion mt-2 shadow-lg rounded " id="accordionPanelsStayOpenExample">

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-Principal">
                            <button className="accordion-button collapsed text-white bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsePrincipal" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                <FontAwesomeIcon icon={faBox} />
                                &nbsp;&nbsp;Produto Principal
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapsePrincipal" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-Principal">
                            <div className="accordion-body ">
                                <div className="row d-flex justify-content-center">
                                    {produtosAutorizados?.filter((x) => { return x.Principal }).map((produtoaut, idx) => (
                                        <div key={produtoaut.IdProduto} className="card col-auto m-3 p-0 bg-dark">
                                            
                                            <div className="card-header d-flex justify-content-end p-0">
                                                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => removerProdutosAutorizados(produtoaut.IdProdutoAutorizado)}></button>
                                            </div>
                                            <div className="card-body ">
                                                <div className="align-items-center">
                                                    <img width="60px" height="60px" className="img-fluid rounded p-1" alt={produtoaut.NmProduto} title={produtoaut.NmProduto} src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + produtos?.filter((prod) => { return prod.IdProduto == produtoaut.IdProduto })[0]?.IdCaracteristicaPessoa} />
                                                </div>
                                            </div>
                                            <span className="position-absolute top-100 start-50 translate-middle badge rounded-pill bg-dark text-dark text-white text-truncate">
                                                {produtos?.filter((prod) => { return prod.IdProduto == produtoaut.IdProduto })[0]?.NmProduto}
                                                <span className="visually-hidden">unread messages</span>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-Agregado">
                            <button className="accordion-button collapsed text-white bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseAgregado" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                <FontAwesomeIcon icon={faBoxesPacking} />
                                &nbsp;&nbsp;Produto Agregado
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseAgregado" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-Agregado">
                            <div className="accordion-body ">
                                <div className="row d-flex justify-content-center">
                                    {produtosAutorizados.filter((x) => { return !x.Principal }).map((produtoaut, idx) => (
                                        <div key={produtoaut.IdProduto} className="card col-auto m-3 p-0 bg-dark">
                                            <div className="card-header d-flex justify-content-end p-0">
                                                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => removerProdutosAutorizados(produtoaut.IdProdutoAutorizado)}></button>
                                            </div>
                                            <div className="card-body">
                                                <div className="align-items-center">
                                                    <img width="60px" height="60px" className="img-fluid rounded p-1" alt={produtoaut.NmProduto} title={produtoaut.NmProduto} src={'http://api.erp.rcasistemas.com.br/api/GetImgCarac?IdCaracteristica=' + produtos?.filter((prod) => { return prod.IdProduto == produtoaut.IdProduto })[0]?.IdCaracteristicaPessoa} />
                                                </div>
                                            </div>
                                            <span className="position-absolute top-100 start-50 translate-middle badge rounded-pill bg-dark text-dark text-white text-truncate">
                                                {produtos?.filter((prod) => { return prod.IdProduto == produtoaut.IdProduto })[0]?.NmProduto}
                                                <span className="visually-hidden">unread messages</span>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Produtos;